"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addNumberCategory = addNumberCategory;
exports.deleteNumberCategory = deleteNumberCategory;
exports.getNumberCategoryList = getNumberCategoryList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getNumberCategoryList() {
  return (0, _request.default)({
    url: '/NumberCategories',
    method: 'get'
  });
}

function addNumberCategory(data) {
  return (0, _request.default)({
    url: '/NumberCategories',
    method: 'post',
    data: data
  });
}

function deleteNumberCategory(mvneServiceCode) {
  return (0, _request.default)({
    url: "/NumberCategories/".concat(mvneServiceCode),
    method: 'delete'
  });
}