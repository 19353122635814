"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addVas = addVas;
exports.addVasGroup = addVasGroup;
exports.deleteVas = deleteVas;
exports.deleteVasGroup = deleteVasGroup;
exports.editVas = editVas;
exports.editVasGroup = editVasGroup;
exports.getVas = getVas;
exports.getVasByCode = getVasByCode;
exports.getVasGroupByCode = getVasGroupByCode;
exports.getVasGroupType = getVasGroupType;
exports.getVasGroups = getVasGroups;
exports.getVasSearch = getVasSearch;

var _request = _interopRequireDefault(require("@/utils/request"));

function getVasGroups() {
  return (0, _request.default)({
    url: '/VasGroups',
    method: 'get'
  });
}

function addVasGroup(data) {
  return (0, _request.default)({
    url: '/VasGroups',
    method: 'post',
    data: data
  });
}

function getVasGroupType() {
  return (0, _request.default)({
    url: '/VasGroups/VasGroupType',
    method: 'get'
  });
}

function getVasGroupByCode(code) {
  return (0, _request.default)({
    url: "/VasGroups/".concat(code),
    method: 'get',
    params: {
      code: code
    }
  });
}

function editVasGroup(code, name, type) {
  return (0, _request.default)({
    url: "/VasGroups/".concat(code),
    method: 'put',
    data: {
      name: name,
      type: type
    }
  });
}

function deleteVasGroup(code) {
  return (0, _request.default)({
    url: "/VasGroups/".concat(code),
    method: 'delete'
  });
}

function getVas() {
  return (0, _request.default)({
    url: '/Vas',
    method: 'get'
  });
}

function getVasSearch() {
  var q = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var vasgroupcode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return (0, _request.default)({
    url: '/Vas/search',
    method: 'get',
    params: {
      q: q,
      vasgroupcode: vasgroupcode
    }
  });
}

function addVas(data) {
  return (0, _request.default)({
    url: '/Vas',
    method: 'post',
    data: data
  });
}

function getVasByCode(code) {
  return (0, _request.default)({
    url: "/Vas/".concat(code),
    method: 'get',
    params: {
      code: code
    }
  });
}

function editVas(code, name, mvneVasId, price, description, vasGroupCode) {
  return (0, _request.default)({
    url: "/Vas/".concat(code),
    method: 'put',
    data: {
      name: name,
      mvneVasId: mvneVasId,
      price: price,
      description: description,
      vasGroupCode: vasGroupCode
    }
  });
}

function deleteVas(code) {
  return (0, _request.default)({
    url: "/Vas/".concat(code),
    method: 'delete'
  });
}