"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUser = addUser;
exports.disableUser = disableUser;
exports.enableUser = enableUser;
exports.getInfo = getInfo;
exports.getUser = getUser;
exports.getUserInfo = getUserInfo;
exports.search = search;
exports.updateUser = updateUser;

require("core-js/modules/es6.function.name");

var _request = _interopRequireDefault(require("@/utils/request"));

function getInfo() {
  return (0, _request.default)({
    url: '/account/getinfo',
    method: 'get'
  });
}

function addUser(_ref) {
  var username = _ref.username,
      name = _ref.name,
      email = _ref.email,
      groupids = _ref.groupids;
  return (0, _request.default)({
    url: '/users',
    method: 'post',
    data: {
      username: username,
      name: name,
      email: email,
      groupids: groupids
    }
  });
}

function getUser() {
  return (0, _request.default)({
    url: '/users',
    method: 'get'
  });
}

function updateUser(_ref2) {
  var id = _ref2.id,
      name = _ref2.name,
      groupids = _ref2.groupids;
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: 'put',
    data: {
      name: name,
      groupids: groupids
    }
  });
}

function getUserInfo(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: 'get'
  });
}

function search(_ref3) {
  var _ref3$offSet = _ref3.offSet,
      offSet = _ref3$offSet === void 0 ? 0 : _ref3$offSet,
      _ref3$limit = _ref3.limit,
      limit = _ref3$limit === void 0 ? 20 : _ref3$limit,
      _ref3$q = _ref3.q,
      q = _ref3$q === void 0 ? '' : _ref3$q,
      _ref3$groupid = _ref3.groupid,
      groupid = _ref3$groupid === void 0 ? '' : _ref3$groupid,
      _ref3$includeTotalCou = _ref3.includeTotalCount,
      includeTotalCount = _ref3$includeTotalCou === void 0 ? false : _ref3$includeTotalCou;
  return (0, _request.default)({
    url: '/users/search',
    method: 'get',
    params: {
      offSet: offSet,
      limit: limit,
      q: q,
      groupid: groupid,
      includeTotalCount: includeTotalCount
    }
  });
}

function enableUser(_ref4) {
  var id = _ref4.id;
  return (0, _request.default)({
    url: "/users/".concat(id, "/EnabledUser"),
    method: 'put'
  });
}

function disableUser(_ref5) {
  var id = _ref5.id;
  return (0, _request.default)({
    url: "/users/".concat(id, "/DisabledUser"),
    method: 'put'
  });
}