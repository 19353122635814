"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSubline = addSubline;
exports.addSupportingDocument = addSupportingDocument;
exports.addUserRemarks = addUserRemarks;
exports.getAccountSearchType = getAccountSearchType;
exports.getActivationDetails = getActivationDetails;
exports.getActivationList = getActivationList;
exports.getCountries = getCountries;
exports.getCustomerTypes = getCustomerTypes;
exports.getDataConsents = getDataConsents;
exports.getDonors = getDonors;
exports.getGenders = getGenders;
exports.getIdentificationTypes = getIdentificationTypes;
exports.getLanguages = getLanguages;
exports.getMobileNo = getMobileNo;
exports.getRegistrationPdf = getRegistrationPdf;
exports.getSalutations = getSalutations;
exports.getStatusList = getStatusList;
exports.quickSearch = quickSearch;
exports.resubmitRegistration = resubmitRegistration;
exports.searchAccount = searchAccount;
exports.searchActivationList = searchActivationList;
exports.submitRegistration = submitRegistration;
exports.submitTempAttachment = submitTempAttachment;

require("core-js/modules/es6.function.name");

var _request = _interopRequireDefault(require("@/utils/request"));

function getCustomerTypes() {
  return (0, _request.default)({
    url: '/CustomerTypes',
    method: 'get'
  });
}

function getIdentificationTypes() {
  return (0, _request.default)({
    url: '/IdentificationTypes',
    method: 'get'
  });
}

function getSalutations() {
  return (0, _request.default)({
    url: '/Salutations',
    method: 'get'
  });
}

function getCountries() {
  return (0, _request.default)({
    url: '/Countries',
    method: 'get'
  });
}

function getGenders() {
  return (0, _request.default)({
    url: '/Genders',
    method: 'get'
  });
}

function getLanguages() {
  return (0, _request.default)({
    url: '/Languages',
    method: 'get'
  });
}

function getDataConsents() {
  return (0, _request.default)({
    url: '/DataConsents',
    method: 'get'
  });
}

function getDonors() {
  return (0, _request.default)({
    url: '/Donors',
    method: 'get'
  });
}

function getMobileNo() {
  var q = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var sc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return (0, _request.default)({
    url: "/Callerids/GetAvailableCallerIds?q=".concat(q, "&sc=").concat(sc),
    method: 'get'
  });
}

function submitRegistration(data, icFronAttachment, icBackAttachment, signCustAttachment, signPartnerAttachment, supportDocAttachment) {
  var json = JSON.stringify(data);
  var formData = new FormData();
  formData.append('json', json);
  formData.append('AttachmentIDFront', icFronAttachment, 'front_image.jpg');
  formData.append('AttachmentIDBack', icBackAttachment, 'back.jpg');
  formData.append('AttachmentSignatureCustomer', signCustAttachment, 'cust_sign.jpg');
  formData.append('AttachmentSignaturePartner', signPartnerAttachment, 'partner_sign.jpg');

  for (var i = 0; i < supportDocAttachment.length; i++) {
    formData.append('AttachmentSupport', supportDocAttachment[i], 'support_doc.jpg');
  } // console.log(json)


  return (0, _request.default)({
    url: '/Registrations',
    method: 'post',
    data: formData
  });
}

function addSubline(data, icFronAttachment, icBackAttachment, signCustAttachment, signPartnerAttachment, supportDocAttachment) {
  var json = JSON.stringify(data);
  var formData = new FormData();
  formData.append('json', json);
  formData.append('AttachmentIDFront', icFronAttachment, 'front_image.jpg');
  formData.append('AttachmentIDBack', icBackAttachment, 'back.jpg');
  formData.append('AttachmentSignatureCustomer', signCustAttachment, 'cust_sign.jpg');
  formData.append('AttachmentSignaturePartner', signPartnerAttachment, 'partner_sign.jpg');

  for (var i = 0; i < supportDocAttachment.length; i++) {
    formData.append('AttachmentSupport', supportDocAttachment[i], 'support_doc.jpg');
  } // console.log(json)


  return (0, _request.default)({
    url: '/Registrations/AddSublines',
    method: 'post',
    data: formData
  });
}

function submitTempAttachment(data, attachment) {
  var json = JSON.stringify(data);
  var formData = new FormData();
  formData.append('json', json);
  formData.append('Attachment', attachment);
  return (0, _request.default)({
    url: '/TempAttachments',
    method: 'post',
    data: formData
  });
}

function getAccountSearchType() {
  return (0, _request.default)({
    url: '/Accounts/SearchType',
    method: 'get'
  });
}

function searchAccount(query) {
  return (0, _request.default)({
    url: '/Accounts',
    method: 'get',
    params: query
  });
}

function getStatusList() {
  return (0, _request.default)({
    url: '/Registrations/StatusList',
    method: 'get'
  });
}

function searchActivationList(_ref) {
  var _ref$idNo = _ref.idNo,
      idNo = _ref$idNo === void 0 ? '' : _ref$idNo,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? '' : _ref$name,
      _ref$mobileNo = _ref.mobileNo,
      mobileNo = _ref$mobileNo === void 0 ? '' : _ref$mobileNo,
      _ref$agentId = _ref.agentId,
      agentId = _ref$agentId === void 0 ? 0 : _ref$agentId,
      _ref$statusCode = _ref.statusCode,
      statusCode = _ref$statusCode === void 0 ? 0 : _ref$statusCode,
      _ref$startDate = _ref.startDate,
      startDate = _ref$startDate === void 0 ? '' : _ref$startDate,
      _ref$endDate = _ref.endDate,
      endDate = _ref$endDate === void 0 ? '' : _ref$endDate,
      _ref$mnp = _ref.mnp,
      mnp = _ref$mnp === void 0 ? false : _ref$mnp,
      _ref$includeTotalCoun = _ref.includeTotalCount,
      includeTotalCount = _ref$includeTotalCoun === void 0 ? false : _ref$includeTotalCoun,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 20 : _ref$limit,
      _ref$offSet = _ref.offSet,
      offSet = _ref$offSet === void 0 ? 0 : _ref$offSet;
  return (0, _request.default)({
    url: '/Registrations/Search',
    method: 'get',
    params: {
      idNo: idNo,
      name: name,
      mobileNo: mobileNo,
      agentId: agentId,
      statusCode: statusCode,
      startDate: startDate,
      endDate: endDate,
      mnp: mnp,
      includeTotalCount: includeTotalCount,
      limit: limit,
      offSet: offSet
    }
  });
}

function getActivationList(_ref2) {
  var _ref2$code = _ref2.code,
      code = _ref2$code === void 0 ? '' : _ref2$code,
      _ref2$agentId = _ref2.agentId,
      agentId = _ref2$agentId === void 0 ? 0 : _ref2$agentId,
      _ref2$startDate = _ref2.startDate,
      startDate = _ref2$startDate === void 0 ? '' : _ref2$startDate,
      _ref2$endDate = _ref2.endDate,
      endDate = _ref2$endDate === void 0 ? '' : _ref2$endDate,
      _ref2$includeTotalCou = _ref2.includeTotalCount,
      includeTotalCount = _ref2$includeTotalCou === void 0 ? false : _ref2$includeTotalCou,
      _ref2$limit = _ref2.limit,
      limit = _ref2$limit === void 0 ? 20 : _ref2$limit,
      _ref2$offSet = _ref2.offSet,
      offSet = _ref2$offSet === void 0 ? 0 : _ref2$offSet;
  return (0, _request.default)({
    url: '/Registrations/Get',
    method: 'get',
    params: {
      code: code,
      agentId: agentId,
      startDate: startDate,
      endDate: endDate,
      includeTotalCount: includeTotalCount,
      limit: limit,
      offSet: offSet
    }
  });
}

function getActivationDetails(id) {
  return (0, _request.default)({
    url: "/Registrations/".concat(id),
    method: 'get'
  });
}

function quickSearch(_ref3) {
  var _ref3$q = _ref3.q,
      q = _ref3$q === void 0 ? '' : _ref3$q,
      _ref3$limit = _ref3.limit,
      limit = _ref3$limit === void 0 ? 2 : _ref3$limit;
  return (0, _request.default)({
    url: '/Agents/QuickSearch',
    method: 'get',
    params: {
      limit: limit,
      q: q
    }
  });
}

function getRegistrationPdf(submissionNo) {
  return (0, _request.default)({
    url: "fms/PostpaidRegistrationForms/".concat(submissionNo, "/FormPdf"),
    method: 'get',
    responseType: 'blob'
  });
}

function addSupportingDocument(submissionNo, SupportingDocumentsAttachment) {
  // const json = JSON.stringify(obj)
  // const obj = {
  // }
  var formData = new FormData(); // formData.append('json', json)

  formData.append('SupportingDocumentsAttachment', SupportingDocumentsAttachment);
  return (0, _request.default)({
    url: "fms/PostpaidRegistrationForms/".concat(submissionNo, "/AddSupportingDocuments"),
    method: 'put',
    data: formData
  });
}

function resubmitRegistration(submissionId) {
  return (0, _request.default)({
    url: "/Registrations/".concat(submissionId, "/Resubmit"),
    method: 'post'
  });
}

function addUserRemarks(submissionId, remark) {
  return (0, _request.default)({
    url: "/Registrations/".concat(submissionId, "/UserRemarks"),
    method: 'post',
    data: {
      remark: remark
    }
  });
}