"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules **/
var planSettingRouter = {
  path: '/RegistrationSettings',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'RegistrationSettings',
  meta: {
    title: 'Registration Settings',
    icon: 'settings',
    roles: ['ActivationSetting_View']
  },
  children: [{
    path: 'vasGroups',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vasGroup/index'));
      });
    },
    meta: {
      title: 'Vas Group',
      icon: 'table_list',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'vasGroupList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vasGroup/list'));
        });
      },
      hidden: true,
      meta: {
        activeMenu: '/RegistrationSettings/vasGroups',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'add',
      name: 'AddVasGroups',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vasGroup/add'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Vas Group',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/vasGroups'
      }
    }, {
      path: 'edit/:code',
      name: 'EditVasGroups',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vasGroup/edit'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Vas Group',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/vasGroups'
      }
    }]
  }, {
    path: 'vas',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vas/index'));
      });
    },
    meta: {
      title: 'Vas',
      icon: 'table',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'vasList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vas/list'));
        });
      },
      hidden: true,
      meta: {
        activeMenu: '/RegistrationSettings/vas',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'add',
      name: 'AddVas',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vas/add'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Vas',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/vas'
      }
    }, {
      path: 'edit/:code',
      name: 'EditVas',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/vas/edit'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Vas',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/vas'
      }
    }]
  }, {
    path: 'packages',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/package/index'));
      });
    },
    meta: {
      title: 'Packages',
      icon: 'package',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'packageList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/package/list'));
        });
      },
      hidden: true,
      meta: {
        activeMenu: '/RegistrationSettings/packages',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'add',
      name: 'AddPackage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/package/add'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Package',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/packages'
      }
    }, {
      path: 'edit/:code',
      name: 'EditPackage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/package/edit'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Package',
        icon: 'registration_add',
        activeMenu: '/RegistrationSettings/packages'
      }
    }]
  }, {
    path: 'projects',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/projects/index'));
      });
    },
    meta: {
      title: 'Projects',
      icon: 'project',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'project.list',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/projects/list'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/projects',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'create',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/projects/create'));
        });
      },
      name: 'project.create',
      hidden: true,
      meta: {
        title: 'Add Project',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/projects'
      }
    }, {
      path: 'edit/:code',
      name: 'project.edit',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/projects/edit'));
        });
      },
      meta: {
        title: 'Edit Project',
        icon: 'table',
        activeMenu: '/RegistrationSettings/projects'
      }
    }]
  }, {
    path: 'identificationtypes',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/identificationTypes/index'));
      });
    },
    meta: {
      title: 'Identification Types',
      icon: 'identification_types'
    },
    children: [{
      path: '',
      name: 'idTypeList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/identificationTypes/list'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/identificationtypes'
      }
    }, {
      path: 'addIdType',
      name: 'AddIdType',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/identificationTypes/add'));
        });
      },
      hidden: true,
      meta: {
        title: 'Add Identification Type',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/identificationtypes'
      }
    }, {
      path: 'editIdType/:id',
      name: 'EditIdType',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/identificationTypes/edit'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Identification Type',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/identificationtypes'
      }
    }]
  }, {
    path: 'caf',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/caf/index'));
      });
    },
    meta: {
      title: 'CAF Form',
      icon: 'form',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'CafList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/caf/listCaf'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/caf',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'addCaf',
      name: 'AddCaf',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/caf/addCaf'));
        });
      },
      hidden: true,
      meta: {
        title: 'Create CAF Form',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/caf'
      }
    }, {
      path: 'editCaf/:id',
      name: 'EditCaf',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/caf/editCaf'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit CAF Form',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/caf'
      }
    }]
  }, {
    path: 'tnc',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/tnc/index'));
      });
    },
    meta: {
      title: 'TNC Form',
      icon: 'form',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'TncList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/tnc/listTnc'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/tnc',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'addTnc',
      name: 'AddTnc',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/tnc/addTnc'));
        });
      },
      hidden: true,
      meta: {
        title: 'Create TNC Form',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/tnc'
      }
    }, {
      path: 'editTnc/:id',
      name: 'EditTnc',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/tnc/editTnc'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit TNC Form',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/tnc'
      }
    }]
  }, {
    path: 'numberrange',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/numberRange/index'));
      });
    },
    meta: {
      title: 'Number Range',
      icon: 'number_range',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'NumberRangeList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/numberRange/list'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/numberrange',
        roles: ['ActivationSetting_View']
      }
    }, {
      path: 'addNumberRange',
      name: 'AddNumberRange',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/numberRange/add'));
        });
      },
      hidden: true,
      meta: {
        title: 'Create Number Range',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/numberrange'
      }
    }, {
      path: 'editNumberRange/:id',
      name: 'EditNumberRange',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/numberRange/edit'));
        });
      },
      hidden: true,
      meta: {
        title: 'Edit Number Range',
        icon: 'edit',
        activeMenu: '/RegistrationSettings/numberrange'
      }
    }]
  }, {
    path: 'numbercategory',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/numberCategory/index'));
      });
    },
    meta: {
      title: 'Number Category',
      icon: 'number_category',
      roles: ['ActivationSetting_View']
    },
    children: [{
      path: '',
      name: 'NumberCategoryList',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/numberCategory/list'));
        });
      },
      meta: {
        activeMenu: '/RegistrationSettings/numbercategory',
        roles: ['ActivationSetting_View']
      }
    }]
  }]
};
var _default = planSettingRouter;
exports.default = _default;