"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addIdentificationType = addIdentificationType;
exports.deleteIdentificationType = deleteIdentificationType;
exports.editIdentificationType = editIdentificationType;
exports.getIdentificationTypeById = getIdentificationTypeById;
exports.getIdentificationTypes = getIdentificationTypes;

var _request = _interopRequireDefault(require("@/utils/request"));

function getIdentificationTypes() {
  return (0, _request.default)({
    url: '/IdentificationTypes',
    method: 'get'
  });
}

function getIdentificationTypeById(id) {
  return (0, _request.default)({
    url: "/IdentificationTypes/".concat(id),
    method: 'get',
    params: {
      id: id
    }
  });
}

function addIdentificationType(data) {
  return (0, _request.default)({
    url: '/IdentificationTypes',
    method: 'post',
    data: data
  });
}

function editIdentificationType(id, name, mvneIdTypeCode) {
  return (0, _request.default)({
    url: "/IdentificationTypes/".concat(id),
    method: 'put',
    data: {
      name: name,
      mvneIdTypeCode: mvneIdTypeCode
    }
  });
}

function deleteIdentificationType(id) {
  return (0, _request.default)({
    url: "/IdentificationTypes/".concat(id),
    method: 'delete'
  });
}