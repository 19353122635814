"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGroups = getGroups;

var _request = _interopRequireDefault(require("@/utils/request"));

function getGroups() {
  return (0, _request.default)({
    url: '/groups',
    method: 'get'
  });
}