"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _vue = _interopRequireDefault(require("vue"));

_vue.default.filter('toCurrency', function (value) {
  if (!value) {
    return '';
  }

  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
    FractionDigits: 2
  });
  return formatter.format(value);
});