"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addNumberRange = addNumberRange;
exports.deleteNumberRange = deleteNumberRange;
exports.editNumberRangeById = editNumberRangeById;
exports.getNumberRangeById = getNumberRangeById;
exports.getNumberRangeList = getNumberRangeList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getNumberRangeList() {
  return (0, _request.default)({
    url: '/NumberRanges',
    method: 'get'
  });
}

function getNumberRangeById(id) {
  return (0, _request.default)({
    url: "/NumberRanges/".concat(id),
    method: 'get',
    params: {
      id: id
    }
  });
}

function editNumberRangeById(id, rangeStart, rangeEnd) {
  return (0, _request.default)({
    url: "/NumberRanges/".concat(id),
    method: 'put',
    data: {
      rangeStart: rangeStart,
      rangeEnd: rangeEnd
    }
  });
}

function addNumberRange(data) {
  return (0, _request.default)({
    url: '/NumberRanges',
    method: 'post',
    data: data
  });
}

function deleteNumberRange(id) {
  return (0, _request.default)({
    url: "/NumberRanges/".concat(id),
    method: 'delete'
  });
}