"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/core-js/modules/es6.promise.js");

require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/core-js/modules/es6.object.assign.js");

require("/home/gitlab-runner/builds/0904fac3/0/bloodseekers/thai-cp-web/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("./filters");

require("@/icons");

require("@/permission");

require("vue-birth-datepicker/dist/vueBirthDatepicker.css");

var _vueLightTimeline = _interopRequireDefault(require("vue-light-timeline"));

// A modern alternative to CSS resets
// lang i18n
// global css
// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _en.default
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)


_vue.default.use(_vueLightTimeline.default);

_vue.default.use(require('vue-moment'));

_vue.default.config.productionTip = false;
console.log(process.env.VUE_APP_AUTH_SERVER_URL);
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});